import { styled } from 'styled-components';
import { Colors } from '../../../../../play-core/styles/Colors';
import { pulsate } from '../../../../../play-core/styles/pulsate';
export const AspectPlaceholder = styled.div.withConfig({
  displayName: "AspectPlaceholder",
  componentId: "sc-heanla-0"
})(["position:relative;background-color:", ";border-radius:2px;width:100%;height:100%;animation:", " 1s ease-in-out infinite alternate;", ";&:before{content:'';float:left;padding-top:", ";}"], Colors.GREY_2a2a2a, pulsate, ({
  isPreview
}) => isPreview && 'animation: initial', ({
  aspectRatio
}) => `${100 / aspectRatio}%`);